﻿import { Loader } from "@googlemaps/js-api-loader";
import Alpine from "alpinejs";

const loader = new Loader({
  apiKey: "AIzaSyDUcI10jWozaf2EqnvH-wZF3-sLxrGWOLI",
  version: "weekly",
});

window.onContactUsSubmit = async (data: any, event: SubmitEvent) => {
  data.status = "submitting";
  try {
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const result = await fetch("/umbraco/api/contactus/post", {
      method: "POST",
      body: formData,
    });
    if (result.ok) {
      data.status = "success";
    } else {
      data.errors = await result.json();
    }
  } finally {
    if (data.status != "success") {
      data.status = "normal";
    }
  }
};

const styles = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#00426a",
      },
      {
        saturation: "-75",
      },
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#00426a",
      },
      {
        saturation: "-27",
      },
      {
        lightness: "22",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#0076a8",
      },
      {
        lightness: "90",
      },
      {
        saturation: "-80",
      },
      {
        gamma: "1.00",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        weight: "0.01",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#00426a",
      },
      {
        lightness: "47",
      },
      {
        saturation: "-75",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        weight: "0.01",
      },
      {
        lightness: "36",
      },
      {
        saturation: "-59",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#0076a8",
      },
      {
        weight: "1.00",
      },
      {
        lightness: "45",
      },
      {
        saturation: "-67",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: "-62",
      },
      {
        visibility: "simplified",
      },
      {
        gamma: "0.66",
      },
      {
        hue: "#0075ff",
      },
      {
        lightness: "17",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#0076a8",
      },
      {
        lightness: "63",
      },
      {
        saturation: "-76",
      },
      {
        weight: "2",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        color: "#0076a8",
      },
      {
        lightness: "66",
      },
      {
        saturation: "-81",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#0076a8",
      },
      {
        lightness: "70",
      },
      {
        saturation: "-78",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry.stroke",
    stylers: [
      {
        lightness: "50",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text",
    stylers: [
      {
        saturation: "-47",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#0076a8",
      },
      {
        visibility: "on",
      },
      {
        lightness: "58",
      },
      {
        saturation: "-44",
      },
      {
        gamma: "0.87",
      },
    ],
  },
];

loader.load().then(async () => {
  window.loadMap = async (id: string, lat: number, lng: number) => {
    const { Map } = await google.maps.importLibrary("maps");

    const map = new Map(document.getElementById(id), {
      center: { lat: lat, lng: lng },
      zoom: 17,
    });
    const marker = new google.maps.Marker({
      map: map,
      position: { lat: lat, lng: lng },
    });
  };
  Alpine.start();
});
